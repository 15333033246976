import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateUser } from '../../actions/auth'
import AuthService from '../../services/AuthService'
import './style.scss'

class NicknamePrompt extends Component {
  state = {
    nickname: ''
  }

  validateNickname = async () => {
    if (this.state.nickname.length > 3) {
      let response = await AuthService.updateProfile({ nickname: this.state.nickname })

      if (response.success) {
        this.props.updateUser(response.data)
      }
    }
  }

  handleChange = (event) => {
    this.setState({ nickname: event.target.value });
  }

  render() {
    return (
      <div className="nickname-prompt-overlay">
        <div className="nickname-prompt-wrapper">
          <div className="title">New challenger detected !</div>
          <div className="subtitle">Please set a powerfull nickname to intimidate your enemies o_O</div>
          <div>Email: {this.props.auth.user.email}</div>
          <input type="text" value={this.state.nickname} className="input" minLength={4} maxLength={20} onChange={this.handleChange} placeholder="XxD4rkS4suk3xX (min 4 chars)" />
          <input type="button" onClick={this.validateNickname} value="let's go !" disabled={this.state.nickname.length < 4} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NicknamePrompt)
import CONFIG from '../config'

class GameService {
  static getToken() {
    const tokenString = localStorage.getItem('token')
    if (!tokenString) {
      return null
    }


    const { token, timestamp } = JSON.parse(tokenString)

    if (timestamp < Date.now()) {
      localStorage.removeItem('token')
      return null
    }

    return token
  }

  static async getMe() {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/me?t=` + Date.now(), {
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static async getAvatars() {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/avatars?t=` + Date.now(), {
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static async updateProfile(userInfos) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/profile`, {
      method: 'post',
      headers: {
        "Authorization": token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userInfos)
    })

    return req.json()
  }

  static async getInventory() {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory?t=` + Date.now(), {
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static async openBox(qty) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory/open/${qty}`, {
      method: 'post',
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static async buyBox(qty) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory/buy/${qty}`, {
      method: 'post',
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static async sellAvatar(avatarId, shinyQuantity, quantity) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory/sell/${avatarId}`, {
      method: 'post',
      headers: {
        authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ shinyQuantity, quantity })
    })
    return req.json()
  }

  static async fuseAvatar(avatarId, shinyQuantity, quantity) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory/fuse/${avatarId}`, {
      method: 'post',
      headers: {
        authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ shinyQuantity, quantity })
    })
    return req.json()
  }

  static async unlockAvatar(avatarId, isShiny) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/inventory/unlock/${avatarId}`, {
      method: 'post',
      headers: {
        authorization: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ isShiny })
    })
    return req.json()
  }

  static async claimQuest(quest) {
    const token = GameService.getToken()

    if (!token) {
      return null
    }

    let req = await fetch(`${CONFIG.API_URL}/user/quest/${quest.code}`, {
      method: 'post',
      headers: {
        authorization: token
      }
    })
    return req.json()
  }

  static logout() {
    localStorage.removeItem('token')
  }
}

export default GameService
import React, { Component } from 'react'
import { connect } from "react-redux"
import LobbyMemberList from '../LobbyMemberList'
import { createLobby, addMember, updateMember, removeMember } from '../../actions/lobby'
import './style.scss'
import MySocket from '../../hoc/MySocket'

class LobbyPanel extends Component {
  state = {
    timestamp: null,
  }

  componentDidMount() {
    this.setState({
      timestamp: Date.now()
    }, this.initSocketEvents)
  }

  componentWillUnmount() {
    MySocket.removeOnMessageCallback('LobbyPanel')
    if (MySocket.socket) {
      // MySocket.socket.send(JSON.stringify({ title: 'lobby-destroy' }))
    }
  }

  joinRoom() {
    if (!this.props.lobby || !this.props.lobby.code) {
      return false
    }

    try {
      console.log('join room ' + this.props.lobby.code)
      MySocket.socket.send(JSON.stringify({ title: 'join-room', data: { code: this.props.lobby.code } }))
    } catch (e) {
      console.log(e.message)
    }
  }

  lobbyHasLeader(lobby) {
    return lobby.members.find(member => member.isLobbyLeader) !== undefined
  }

  initSocketEvents = () => {
    MySocket.setOnMessageCallback('LobbyPanel', (message) => {
      switch (message.title) {
        case 'lobby-validate':
          this.onValidLobby(message.data)
          break
        case 'lobby-controller-connected':
          this.onLobbyConnect(message.data)
          break
        case 'lobby-controller-disconnected':
          this.onLobbyDisconnect(message.data)
          break
        case 'lobby-member-updated':
          this.onLobbyMemberUpdated(message.data)
          break
        default:
          break
      }
    })
    if (!this.props.lobby.code) {
      MySocket.socket.send(JSON.stringify({ title: 'lobby-create', data: { id: this.state.timestamp } }))
    }
  }

  onValidLobby = data => {
    this.props.createLobby(data.code)
  }

  onLobbyConnect = data => {
    this.props.addMember(data.member)
  }

  onLobbyMemberUpdated = data => {
    this.props.updateMember(data.member.id, data.member)
  }

  onLobbyDisconnect = data => {
    this.props.removeMember(data.id)
  }

  render = () => (
    <div className="lobby-panel">
      <div className="title">{this.props.lobby.code ? `Lobby ${this.props.lobby.code}` : 'Creating lobby...'}</div>
      {this.props.lobby.code &&
        (<>
          <div className="subtitle">To connect your phone to this lobby download the VGC mobile app and enter lobby code <b>{this.props.lobby.code}</b> </div>
          <LobbyMemberList members={this.props.lobby.members}></LobbyMemberList>
        </>)
      }
    </div>
  )
}

const mapStateToProps = ({ lobby }) => ({
  lobby
})

const mapDispatchToProps = dispatch => ({
  createLobby: code => dispatch(createLobby(code)),
  addMember: member => dispatch(addMember(member)),
  updateMember: (memberId, member) => dispatch(updateMember(memberId, member)),
  removeMember: memberId => dispatch(removeMember(memberId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LobbyPanel)
import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateUser } from '../../actions/auth'
import AuthService from '../../services/AuthService'
import './style.scss'
import InventoryPanel from '../InventoryPanel'

class ProfilePanel extends Component {
  state = {
    avatar: null,
    showOnlyEarnedAvatars: true,
    inventoryWindowOpened: false,
    buyAvatarWindow: {
      opened: false,
      avatar: null
    }
  }

  componentDidMount() {
    this.setState({
      avatar: this.props.auth.user.avatar
    })
  }

  handleClickInventoryButton = () => {
    this.setState({
      inventoryWindowOpened: true
    })
  }

  validateProfile = async () => {
    let response = await AuthService.updateProfile({ avatar: this.state.avatar })

    if (response.success) {
      // this.props.updateUser(response.data)
      this.props.onCancel()
    }
  }

  handleChange = (event) => {
    this.setState({ nickname: event.target.value });
  }

  handleClickAvatar = avatar => {
    if (!this.isActualAvatar(avatar)) {
      this.setState({
        avatar
      })
    }
  }

  handleReturnClick = async () => {
    this.setState({ inventoryWindowOpened: false })
  }

  isSelectedAvatar = avatar => {
    return this.state.avatar && this.state.avatar.id === avatar.id && this.state.avatar.shiny === avatar.shiny
  }

  getAvatarItemClassName = avatar => {
    let className = 'avatar-item'
    className += this.isSelectedAvatar(avatar) ? ' selected' : ''
    className += avatar.isLegendary ? ' legendary' : ''
    if (!avatar.isLegendary) {
      switch (avatar.rank) {
        case 3:
          className += ' epic'
          break
        case 2:
          className += ' rare'
          break
        default:
          break
      }
    }
    className += this.isActualAvatar(avatar) ? ' disabled' : ''
    return className
  }

  isActualAvatar = avatar => {
    if (!avatar) {
      return false
    }
    return this.props.auth.user.avatar.id === avatar.id && this.props.auth.user.avatar.shiny === avatar.shiny
  }

  getSortedAvatarList = () => {
    return this.props.auth.user.unlockedAvatars
      .map(avatarItem => this.isActualAvatar(avatarItem) ? ({ ...avatarItem, index: 0 }) : avatarItem)
      .sort((a, b) => a.index - b.index)
  }

  render() {
    return (
      <div className="profile-panel-overlay">
        {this.state.inventoryWindowOpened && <InventoryPanel handleReturnClick={this.handleReturnClick} />}
        <div className="profile-panel-wrapper">
          <div className="title">
            <div>AVATAR SELECTOR</div>
            <div className='spacer' />
            <input type='button' value='Inventory' onClick={() => this.handleClickInventoryButton()} />
          </div>
          <div className="avatars-wrapper">
            {this.getSortedAvatarList().map((avatar, index) => (
              <div
                className={this.getAvatarItemClassName(avatar)}
                key={index} style={{ backgroundImage: avatar.image ? `url(${avatar.image})` : '' }} onClick={() => this.handleClickAvatar(avatar)}>
                {avatar.shiny && <img className="star" src="/assets/images/star.png" alt="star_img" />}
              </div>
            ))}
          </div>
          <div className="buttons-wrapper">
            <input type="button" onClick={this.props.onCancel} value="Cancel" />
            <input type="button" onClick={this.validateProfile} value="Save" disabled={this.isActualAvatar(this.state.avatar)} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePanel)
import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateUser } from '../../actions/auth'
import AuthService from '../../services/AuthService'
import './style.scss'
import InventoryPanel from '../InventoryPanel'

const QUEST_STATUS = {
  PENDING: "QUEST_STATUS_PENDING",
  COMPLETED: "QUEST_STATUS_COMPLETED",
  CLAIMED: "QUEST_STATUS_CLAIMED",
}

class QuestPanel extends Component {
  state = {
    timeLeft: null,
    busy: false
  }

  computeTimeLeft = () => {
    let time = this.state.timeLeft
    let hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    let minutes = Math.floor(time / 60);
    let seconds = time - minutes * 60;

    return {
      hours,
      minutes,
      seconds
    }
  }

  componentDidMount() {
    this.setState({
      timeLeft: this.props.auth.user.dailys.resetTime
    })
    this.inverval = setInterval(() => {
      this.setState(prevState => ({
        timeLeft: prevState.timeLeft - 1
      }))
    }, 1000)
  }

  componentWillMount() {
    clearInterval(this.inverval)
  }

  claimQuestRewards = async (quest) => {
    this.setState({
      busy: true
    }, async () => {
      let response = await AuthService.claimQuest(quest)

      if (response.success) {
        this.setState({
          busy: false
        })

        const { unlockedAvatars, ...user } = response.data
        this.props.updateUser(user)
      }
    })
  }

  render() {
    const computedTimeLeft = this.computeTimeLeft()

    return (
      <div className="quest-panel-overlay">
        {this.state.inventoryWindowOpened && <InventoryPanel handleReturnClick={this.handleReturnClick} />}
        <div className="quest-panel-wrapper">
          <div className="title">
            <div>YOUR QUESTS</div>
            <div className="spacer"></div>
            <div className="time-left">{computedTimeLeft.hours}:{computedTimeLeft.minutes}:{computedTimeLeft.seconds}</div>
          </div>
          <div className="quest-list-wrapper">
            {this.props.auth.user.dailys.quests.map((quest, index) => (
              <div
                className="quest-item"
                key={index}>
                <div className={`quest-item-reward${quest.reward.boxes ? ' boxes' : ''}${quest.reward.coins ? ' coins' : ''}`}>
                  <div className="quest-item-reward-count">{quest.reward.boxes ? quest.reward.boxes : quest.reward.coins}</div>
                </div>
                <div className="quest-item-description">
                  <div className="quest-item-title">{quest.relatedGame !== undefined && `[${quest.relatedGame.title.toUpperCase()}] - `}{quest.title}</div>
                  <div className="quest-item-text">{quest.text}</div>
                </div>
                <div className='spacer' />
                <div className="quest-item-buttons">
                  {quest.status === QUEST_STATUS.COMPLETED && (
                    <input type="button" onClick={() => this.claimQuestRewards(quest)} value="CLAIM" disabled={this.state.busy} />
                  )}
                  {quest.status === QUEST_STATUS.CLAIMED && (
                    <input type="button" value="CLAIMED" disabled={true} />
                  )}
                  {quest.status === QUEST_STATUS.PENDING && (
                    <div>You did it {quest.count} times for now !</div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="buttons-wrapper">
            <input type="button" onClick={this.props.onCancel} value="Cancel" />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestPanel)
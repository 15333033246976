import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import './style.scss'

const HorizontalList = ({ items = [] }) => {
  const activated = items.find(x => x.isActivated)
  if (activated) {
    return <Redirect to={activated.link} />
  } else {
    return (<div className="horizontal-list">
      {items.map((item, index) => (
        <Link to={item.link ? item.link : null} className={`item${item.selected ? ' selected' : ''}`} style={{ backgroundImage: item.background ? `url(${item.background})` : 'none' }} key={index}>
          <div className="title">{item.title}</div>
          <div className="subtitle">{item.subtitle}</div>
          {item.pin && <div className={`pin${item.pin === 'ONLINE' ? ' active' : ' inactive'}`}>{item.pin}</div>}
        </Link>
      ))}
    </div>)
  }
}

export default HorizontalList
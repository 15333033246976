import { lobbyConstants } from "../constants"

export const createLobby = code => ({
  type: lobbyConstants.LOBBY_CREATE,
  code
})

export const addMember = member => ({
  type: lobbyConstants.LOBBY_ADD_MEMBER,
  member
})

export const removeMember = memberId => ({
  type: lobbyConstants.LOBBY_REMOVE_MEMBER,
  memberId
})

export const updateMember = (memberId, member) => ({
  type: lobbyConstants.LOBBY_UPDATE_MEMBER,
  memberId,
  member
})
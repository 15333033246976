import React, { Component } from "react"
import { Link } from "react-router-dom"
import './style.scss'


class Unknown extends Component {
  render = () => (
    <div className="unknown page">
      <div>
        <div className="title">404</div>
        <div className="subtitle">You should not be here o_O</div>
        <Link to="/home"><button className="return">Go back to homepage</button></Link>
      </div>
    </div>
  )
}

export default Unknown
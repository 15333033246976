import { cursorConstants } from '../constants';

const initialState = { page: null, component: null, element: null };

export function cursor(state = initialState, action) {
  switch (action.type) {
    case cursorConstants.CURSOR_UPDATE:
      return {
        ...state,
        ...action.cursor
      };
    default:
      return state
  }
}
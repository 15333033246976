import React, { Component } from 'react'
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom'
import { updateCursor } from '../../actions/cursor'
import MenuPanel from '../../containers/MenuPanel'
import GameService from '../../services/GameService'
import './style.scss'

class Play extends Component {
  state = {
    actualGame: 'none',
    menuIsOpen: false
  }

  componentDidMount() {
    this.getGame()

    this.props.updateCursor({
      page: 'play',
      component: 'none',
      element: undefined
    })
  }

  async getGame() {
    const { match } = this.props
    const { params } = match
    const { gameTag } = params

    let actualGame = await GameService.getOneWithTag(gameTag)

    this.setState({
      actualGame
    })
  }

  onClickMenuButton = () => {
    this.props.updateCursor({
      ...this.props.cursor,
      component: 'menu'
    })
  }

  closeMenu = () => {
    this.props.updateCursor({
      page: 'play',
      component: 'none',
      element: undefined
    })
  }

  render = () => {
    const { actualGame } = this.state

    if (actualGame !== 'none' && !actualGame) {
      return <Redirect to="/unknown" />
    }

    return (
      <div className="play page" style={{ backgroundImage: actualGame.picture ? `url(${actualGame.picture})` : 'none' }}>
        <MenuPanel onClickCloseMenuButton={this.closeMenu}></MenuPanel>
        {/* <button className="menu" onClick={this.onClickMenuButton}>Menu</button> */}
        {actualGame !== 'none' && actualGame && actualGame.status === 'ONLINE' && (
          <iframe title="gameContainer" src={actualGame.url + "?lobbyCode=" + this.props.lobby.code} className="gameContainer" />
        )}

      </div>
    )
  }
}

const mapStateToProps = ({ lobby }) => ({
  lobby
})

const mapDispatchToProps = dispatch => ({
  updateCursor: cursor => dispatch(updateCursor(cursor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Play)
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './pages/Home';
import Unknown from './pages/Unknown';
import Header from './containers/Header';
import Play from './pages/Play';
import NicknamePrompt from './containers/NicknamePrompt'
import AuthService from './services/AuthService'
import { updateUser } from './actions/auth'
import { connect } from "react-redux"
import Auth from './pages/Auth';
import MySocket from './hoc/MySocket';

class App extends Component {
  state = {
    loaded: false
  }

  timeout

  componentDidMount() {
    MySocket.open()

    if (this.props.auth.token) {
      this.getMe()
    }

    this.timeout = setTimeout(() => {
      this.setState({ loaded: MySocket.socket })
    }, 5000)
  }

  componentDidUpdate(lastProps) {
    if (!lastProps.auth.token && this.props.auth.token !== undefined) {
      this.getMe()
    }
  }

  async getMe() {
    let response = await AuthService.getMe()

    if (response.success) {
      this.props.updateUser(response.data)
    }
  }

  componentWillUnmount() {
    MySocket.close()
    clearTimeout(this.timeout)
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          {new Array(15).fill(null).map((element, index) => <div className="firefly" key={index} />)}
          {this.state.loaded && (
            <div className="App-content">
              <Header></Header>
              {(this.props.auth.user && !this.props.auth.user.nickname) && <NicknamePrompt></NicknamePrompt>}
              <Switch>
                <Route path="/play/:gameTag" exact component={Play} />
                <Route path="/home" exact component={Home} />
                <Route path="/auth" exact component={Auth} />
                <Route path="/" exact component={Home} />
                <Route component={Unknown} />
              </Switch>
            </div>
          )}
          {!this.state.loaded && (
            <div className="App-opener">
              <div className="title">WELCOME TO VGC</div>
              <img className="logo" src="/assets/images/logo_white.png" alt="logo" />
            </div>
          )}

        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

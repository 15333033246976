import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateCursor } from '../../actions/cursor'
import MySocket from '../../hoc/MySocket'
import './style.scss'

class MiniGame extends Component {
  state = {
    game: null
  }

  componentDidMount() {
    this.initSocketEvents()
  }

  componentWillUnmount() {
    this.stopMiniGame()
    MySocket.removeOnMessageCallback('MiniGame')
  }

  joinRoom() {
    if (!this.props.lobby || !this.props.lobby.code) {
      return false
    }

    try {
      console.log('join room ' + this.props.lobby.code)
      MySocket.socket.send(JSON.stringify({ title: 'join-room', data: { code: this.props.lobby.code, attributes: { onlyMiniGame: true } } }))
    } catch (e) {
      console.log(e.message)
    }
  }

  initSocketEvents = () => {
    MySocket.setOnMessageCallback('MiniGame', (message) => {
      switch (message.title) {
        case 'controller-action':
          this.onControllerAction(message.data)
          break
        case 'minigame-update':
          this.onMinigameUpdate(message.data)
          break
        default:
          break
      }
    })
  }

  isValidCursor() {
    return this.props.cursor && this.props.cursor.component === "MiniGame"
  }

  onControllerAction = data => {
    if (!this.isValidCursor()) {
      return false
    }

    if (!data.member.isLobbyLeader) {
      return false
    }

    if (data.action === "menu") {
      if (this.state.game && this.state.game.scoreboard.length !== 0) {
        this.props.updateCursor({
          ...this.props.cursor,
          component: "GameList",
          element: {
            index: 0
          }
        })
        this.stopMiniGame()
      } else {
        this.setState({
          game: null
        })
      }
    }
  }

  stopMiniGame() {
    this.setState({
      game: null
    })
    MySocket.socket.send(JSON.stringify({ title: 'lobby-stop-minigame', data: { code: this.props.lobby.code } }))
  }

  onMinigameUpdate = data => {
    this.setState({
      game: data.state
    })
  }

  getTileClassName(rowIndex, tileIndex, tile) {
    let className = 'tile'

    switch (tile) {
      case '1':
        className += ' rock'
        break
      case '2':
        className += ' bird' + (tileIndex % 2 === 0 ? '1' : '2')
        break
      case '3':
        className += ' box'
        break
      default:
        className += ' char'
        if (rowIndex === 2) {
          className += ' crouched'
        } else if (rowIndex === 0) {
          className += ' jump'
        }
    }

    return className
  }

  getPlayerImage(tile) {
    if (tile.length > 1) {
      if (this.props.lobby.members.length) {
        const player = this.props.lobby.members.find(member => member.id === tile)
        return player ? `url(${player.picture})` : null
      }
    } else {
      return null
    }
  }

  getPlayerName(tile) {
    if (tile.length > 1) {
      if (this.props.lobby.members.length) {
        const player = this.props.lobby.members.find(member => member.id === tile)
        return player ? player.nickname : null
      }
    } else {
      return null
    }
  }

  getKey(tileIndex) {
    let objectCount = this.state.game.destroyedObjects || 0
    for (let columnIndex = 0; columnIndex < tileIndex; columnIndex++) {
      for (let rowIndex = 0; rowIndex < this.state.game.world.length; rowIndex++) {
        const tile = this.state.game.world[rowIndex][columnIndex]
        if (['1', '2', '3'].find(item => item === tile)) {
          objectCount++
        }
      }
    }
    return objectCount
  }

  computedGameState() {
    const state = {
      players: [],
      obstacles: [],
      xCount: 0,
      yCount: 0,
    }

    if (this.state.game && this.state.game.world) {
      state.yCount = this.state.game.world.length
      state.xCount = this.state.game.world[0].length
      for (let tileIndex = 0; tileIndex < state.xCount; tileIndex++) {
        for (let rowIndex = 0; rowIndex < state.yCount; rowIndex++) {
          const tile = this.state.game.world[rowIndex][tileIndex]
          const tileObject = {
            tile,
            rowIndex,
            tileIndex
          }
          if (tile.length > 1) {
            state.players.push({
              ...tileObject,
              key: tile
            })
          } else if (['1', '2', '3'].find(item => item === tile)) {
            state.obstacles.push({
              ...tileObject,
              key: state.obstacles.length + (this.state.game.destroyedObjects || 0)
            })
          }
        }
      }
    }
    return state
  }

  render() {
    const gameState = this.computedGameState()
    const objects = [...gameState.players, ...gameState.obstacles]
    const showMinigame = objects.length !== 0 && gameState.players.length !== 0
    const showScoreboard = this.state.game !== null && this.state.game.scoreboard.length !== 0 && gameState.players.length === 0

    if (!this.props.lobby || !this.props.lobby.members.length) {
      return null
    }

    return (
      <div className="minigame-wrapper">
        {showMinigame && objects.map(objectItem => (
          <div
            key={objectItem.key}
            index={objectItem.key}
            className={this.getTileClassName(objectItem.rowIndex, objectItem.tileIndex, objectItem.tile)}
            style={
              {
                left: objectItem.tileIndex * (100 / gameState.xCount) + '%',
                bottom: objectItem.tile.length > 1 ? '0%' : 100 - (1 + objectItem.rowIndex) * (100 / gameState.yCount) + '%',
                width: (100 / gameState.xCount) + '%',
                backgroundImage: this.getPlayerImage(objectItem.tile),
                transform: ['1', '3'].find(type => type === objectItem.tile) ? 'rotateZ(' + 22 * (objectItem.tileIndex) + 'deg)' : null,
                transitionDuration: 1 / (1 + this.state.game.speed) + 's'
              }}>
            {this.getPlayerName(objectItem.tile) ? <div className="player-name">{this.getPlayerName(objectItem.tile)}</div> : null}
          </div>))}
        {showScoreboard && this.state.game.scoreboard.sort((a, b) => a.points - b.points).map((scordBoardItem, index) => (
          <div
            key={scordBoardItem.playerId}
            className={'tile char'}
            style={
              {
                left: index * (100 / gameState.xCount) + '%',
                bottom: '0%',
                width: (100 / gameState.xCount) + '%',
                backgroundImage: this.getPlayerImage(scordBoardItem.playerId)
              }}>
            <div className="player-name">{this.getPlayerName(scordBoardItem.playerId)}</div>
            <div className="player-score">{Math.round(scordBoardItem.points)}pts</div>
          </div>))}
      </div>
    )
  }
}

const mapStateToProps = ({ lobby, cursor }) => ({
  lobby,
  cursor
})

const mapDispatchToProps = dispatch => ({
  updateCursor: cursor => dispatch(updateCursor(cursor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniGame)
import React from 'react'
import './style.scss'

const VerticalList = ({ items = [] }) => (
  <div className="vertical-list">
    {items.map((item, index) => (
      <div className="item" key={index}>
        <div className="title">{item.title}</div>
        <div className="icon" style={{ backgroundImage: item.icon ? `url(${item.icon})` : 'none' }}></div>
      </div>
    ))}
  </div>
)

export default VerticalList
import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateUser } from '../../actions/auth'
import { Link } from 'react-router-dom'
import CONFIG from '../../config'
import ProfilePanel from '../ProfilePanel'
import QuestPanel from '../QuestPanel'
import './style.scss'
import MySocket from '../../hoc/MySocket'

class Header extends Component {
  state = {
    profilePanelIsOpen: false,
    questPanelIsOpen: false,
  }

  componentDidMount() {
    this.initSocketEvents()
  }

  componentWillUnmount() {
    MySocket.removeOnMessageCallback('Header')
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.auth.user && this.props.auth.user !== null) {
      this.userConnect()
    }
  }

  handleClickProfileWrapper = () => {
    this.setState({
      profilePanelIsOpen: true,
      questPanelIsOpen: false
    })
  }

  handleCancelProfilePanel = () => {
    this.setState({
      profilePanelIsOpen: false
    })
  }

  handleClickQuestWrapper = () => {
    this.setState({
      questPanelIsOpen: true,
      profilePanelIsOpen: false
    })
  }

  handleCancelQuestPanel = () => {
    this.setState({
      questPanelIsOpen: false
    })
  }

  initSocketEvents = () => {
    MySocket.setOnMessageCallback('Header', (message) => {
      switch (message.title) {
        case 'user-updated':
          this.userUpdated(message.data)
          break
        default:
          break
      }
    })
    this.userConnect()
    // MySocket.socket.onopen = () => {
    //   MySocket.socket.onopen()
    //   this.userConnect()
    // }
  }

  userConnect = () => {
    const { user } = this.props.auth

    if (user) {
      MySocket.socket.send(JSON.stringify({ title: 'user-connected', data: user }))
    }
  }

  userUpdated = data => {
    this.props.updateUser(data.user)
  }

  completedQuestsCount() {
    const { user } = this.props.auth
    const { dailys } = user
    const { quests } = dailys

    return quests ? quests.filter(quest => quest.status === 'QUEST_STATUS_COMPLETED').length : 0
  }

  render() {
    const { user } = this.props.auth
    if (window.screen.availWidth < 960) {
      return (
        <header>
          <Link to="/">
            <img className="logo" src="/assets/images/logo_white.png" alt="logo" />
            <div className="title">
              Virtual Gaming Console
          </div>
          </Link>
          <div className="spacer" />
        </header>
      )
    }

    return (
      <>
        <header>
          <Link to="/">
            <img className="logo" src="/assets/images/logo_white.png" alt="logo" />
            <div className="title">
              Virtual Gaming Console
          </div>
          </Link>
          <div className="spacer" />
          {(user && user.dailys) && (
            <div className="quest-wrapper wrapper" onClick={this.handleClickQuestWrapper}>
              <div className="quest-wrapper-title">QUESTS</div>
              {this.completedQuestsCount() ? <div className="pin">{this.completedQuestsCount()}</div> : null}
            </div>
          )}
          {(user && user.nickname) && (
            <div className="profile-wrapper wrapper" onClick={this.handleClickProfileWrapper}>
              <div className="nickname-and-coins">
                <div className="nickname">{user.nickname}</div>
                {/* <div className="coins">
                  <div>{user.coins}</div>
                  <img src="/assets/images/coin.png" alt="logo" />
                </div> */}
              </div>
              <div style={{ backgroundImage: `url(${user.avatarImage})` }} alt={user.nickname + "'s avatar"} className="avatar-img"></div>
              {user.boxes ? <div className="pin">{user.boxes}</div> : null}
            </div>
          )}
          {!user && (
            (
              <a href={CONFIG.API_URL + "/user/signin"} className="signin-btn">
                <img src="/assets/images/btn_google_signin.png" alt="Sign in with Google" />
              </a>
            )
          )}
        </header>
        {this.state.profilePanelIsOpen && (
          <ProfilePanel onCancel={this.handleCancelProfilePanel} />
        )}
        {this.state.questPanelIsOpen && (
          <QuestPanel onCancel={this.handleCancelQuestPanel} />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
import CONFIG from '../config'

class GameService {
  static async getAll() {
    let req = await fetch(`${CONFIG.API_URL}/game`)
    return req.json()
  }

  static async getOneWithId(gameId) {
    let req = await fetch(`${CONFIG.API_URL}/game/${gameId}`)
    return req.json()
  }

  static async getOneWithTag(gameTag) {
    let req = await fetch(`${CONFIG.API_URL}/game/${gameTag}`)
    return req.json()
  }
}

export default GameService
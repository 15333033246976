import { authConstants } from '../constants';

const { token } = JSON.parse(localStorage.getItem('token')) || {}
const initialState = { user: null, token };

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.AUTH_LOGIN:
      return {
        ...state,
        token: action.token
      }
    case authConstants.AUTH_UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user
        }
      }
    case authConstants.AUTH_LOGOUT:
      return {
        ...state,
        user: null
      }
    default:
      return state
  }
}
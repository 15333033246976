import React, { Component } from 'react'
import VerticalList from '../../presentationals/VerticalList'

class LobbyMemberList extends Component {
  memberToItem = (member) => ({
    title: (<><div>{member.nickname}</div>{member.isLobbyLeader ? <img src="/assets/images/crown.svg" alt="crown"></img> : ''}</>),
    icon: member.picture,
    link: member.nickname.replace(" ", "-").toLowerCase()
  })

  render() {
    const { members = [] } = this.props

    return (
      <VerticalList
        items={members.map(member => this.memberToItem(member))}
      ></VerticalList>
    )
  }
}

export default LobbyMemberList
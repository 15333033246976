import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { login } from '../../actions/auth'
import { connect } from "react-redux"
import './style.scss'

class Auth extends Component {
  state = {
    redirect: null,
    to: 3
  }

  interval = null

  componentDidMount() {
    let query = new URLSearchParams(window.location.search)
    let token = query.get("token")

    if (!token) {
      this.setState({
        redirect: '/home'
      })
    } else {
      localStorage.setItem('token', JSON.stringify({ token, timestamp: Date.now() + 1000 * 60 * 60 * 23 }))
      this.props.login(token)

      this.interval = setInterval(() => {
        if (this.state.to !== 0) {
          this.setState({
            to: this.state.to - 1
          })
        } else {
          this.setState({
            redirect: '/home'
          })
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div className="auth page">
        <div>
          <div className="title">Successfully sign in</div>
          <div className="subtitle">Redirecting you to the homepage in {this.state.to}s</div>
          <Link to="/home"><button className="return">Go back to homepage</button></Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  login: token => dispatch(login(token)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth)
import CONFIG from '../config'

class MySocket {
  static socket = null
  static open() {
    this.socket = new WebSocket(CONFIG.WS_API_URL)
    this.socket.onmessage = (evt) => {
      const data = JSON.parse(evt.data);
      this.onMessageCallbacks.forEach(item => item[1](data))
    }
  }
  static close() {
    this.socket.close()
  }

  static onMessageCallbacks = []
  static setOnMessageCallback(componentName, callback) {
    const componentCallbackIndex = this.onMessageCallbacks.findIndex(item => item[0] === componentName)
    if (componentCallbackIndex >= 0) {
      this.onMessageCallbacks[componentCallbackIndex][1] = callback
    } else {
      this.onMessageCallbacks.push([componentName, callback])
    }
  }
  static removeOnMessageCallback(componentName) {
    this.onMessageCallbacks = this.onMessageCallbacks.filter(item => item[0] !== componentName)
  }
}

export default MySocket
import React, { Component } from 'react'
import GameList from '../GameList'
import './style.scss'
import GameService from '../../services/GameService'

class GameListPanel extends Component {
  state = {
    games: []
  }

  componentDidMount() {
    this.getGames()
  }

  async getGames() {
    let games = await GameService.getAll()

    this.setState({
      games
    })
  }

  render = () => (
    <div className="game-list-panel">
      <div className="title">
        Games
      </div>
      <GameList games={this.state.games}></GameList>
    </div>
  )
}

export default GameListPanel;
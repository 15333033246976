import React, { Component } from 'react'
import { connect } from "react-redux"
import { updateUser } from '../../actions/auth'
import CONFIG from '../../config'
import AuthService from '../../services/AuthService'
import './style.scss'

const BOXCOST = 500

class InventoryPanel extends Component {
  state = {
    boxSelected: false,
    avatarSelected: null,
    isShiny: false,
    fusing: null,
    selling: null,
    loots: {
      unlockedAvatar: null,
      avatars: [],
      coins: 0
    },
    inventory: {
      boxes: 0,
      coins: 0,
      ownedAvatars: []
    },
    busy: false
  }

  componentDidMount() {
    this.getInventory()
  }

  async getInventory() {
    let response = await AuthService.getInventory()

    if (response.success) {
      this.setState({
        inventory: response.data
      })
    }
  }

  handleClickBoxWrapper = () => {
    this.setState({
      boxSelected: !this.state.boxSelected,
      avatarSelected: null,
      isShiny: false,
      fusing: null,
      selling: null,
    })
  }

  handleCloseLoots = () => {
    this.setState({
      loots: {
        unlockedAvatar: null,
        avatars: [],
        coins: 0
      }
    })
  }

  sellAvatar = (avatarId) => {
    this.setState({
      busy: true
    }, async () => {
      let response = await AuthService.sellAvatar(
        avatarId,
        this.state.selling.filter(x => x === true).length,
        this.state.selling.filter(x => x === false).length
      )

      if (response.success) {
        let avatarSelected = this.state.avatarSelected
        let isShiny = this.state.isShiny
        if (response.data.inventory.ownedAvatars.find(x => x.avatar.id === this.state.avatarSelected) === undefined) {
          avatarSelected = null
          isShiny = false
        } else {
          const rawItem = response.data.inventory.ownedAvatars.find(x => x.avatar.id === this.state.avatarSelected)
          if (rawItem.shinyQuantity <= 0 && isShiny) {
            isShiny = false
          } else if (rawItem.quantity <= 0 && !isShiny) {
            isShiny = true
          }
        }

        this.setState({
          inventory: response.data.inventory,
          busy: false,
          avatarSelected,
          isShiny,
          selling: null,
          loots: {
            ...this.state.loots,
            coins: response.data.money
          }
        })

        const { unlockedAvatars, ...user } = response.data.user
        this.props.updateUser(user)
      }
    })
  }

  openBox = async (qty) => {
    this.setState({
      busy: true
    }, async () => {
      let response = await AuthService.openBox(qty)

      if (response.success) {
        this.setState({
          loots: {
            ...this.state.loots,
            avatars: response.data.loots
          },
          inventory: response.data.inventory,
          busy: false
        })

        const { unlockedAvatars, ...user } = response.data.user
        this.props.updateUser(user)
      }
    })
  }

  buyBoxes = async (qty) => {
    this.setState({
      busy: true
    }, async () => {
      let response = await AuthService.buyBox(qty)

      if (response.success) {
        this.setState({
          busy: false
        })

        const { unlockedAvatars, ...user } = response.data
        this.props.updateUser(user)
      }
    })

  }

  fuseAvatar = (avatarId) => {
    if (this.state.fusing[0] !== null && this.state.fusing[1] !== null) {
      this.setState({
        busy: true
      }, async () => {
        let response = await AuthService.fuseAvatar(
          avatarId,
          this.state.fusing.filter(x => x === true).length,
          this.state.fusing.filter(x => x === false).length
        )

        if (response.success) {
          this.setState({
            inventory: response.data.inventory,
            avatarSelected: response.data.fusedAvatar.avatarId,
            isShiny: response.data.isShiny,
            fusing: null,
            busy: false
          })
        }
      })
    }
  }

  unlockAvatar = (avatarId) => {
    if (!this.avatarAlreadyUnlocked(avatarId)) {
      this.setState({
        busy: true
      }, async () => {
        let response = await AuthService.unlockAvatar(avatarId, this.state.isShiny)

        if (response.success) {
          let avatarSelected = this.state.avatarSelected
          let isShiny = this.state.isShiny
          if (this.getSelectedAvatar().quantity + this.getSelectedAvatar().shinyQuantity === 1) {
            avatarSelected = null
            isShiny = false
          } else if (this.getSelectedAvatar().shinyQuantity === 1 && this.state.isShiny) {
            isShiny = false
          } else if (this.getSelectedAvatar().quantity === 1 && !this.state.isShiny) {
            isShiny = true
          }

          this.setState({
            inventory: response.data.inventory,
            avatarSelected,
            isShiny,
            busy: false,
            loots: {
              ...this.state.loots,
              unlockedAvatar: response.data.unlockedAvatar
            }
          })

          this.props.updateUser(response.data.user)
        }
      })
    }
  }

  toggleShiny = () => {
    this.setState({
      isShiny: !this.state.isShiny
    })
  }

  getSelectedAvatar = () => this.state.inventory.ownedAvatars.find(avatar => avatar.avatar.id === this.state.avatarSelected)

  getRightPanelBackground = () => {
    if (this.state.fusing !== null) {
      return this.getAvatarImage({ avatar: { image: this.getSelectedAvatar().avatar.evolution } }, false)
    } else if (this.state.boxSelected) {
      return "url('/assets/images/egg.png')"
    } else if (this.state.avatarSelected) {
      return this.getAvatarImage(this.getSelectedAvatar(), this.state.isShiny)
    } else {
      return null
    }
  }

  getRightPanelSelectedItemName = () => {
    if (this.state.boxSelected) {
      return "Egg"
    } else if (this.state.avatarSelected) {
      return this.getSelectedAvatar().avatar.names.en
    } else {
      return null
    }
  }

  getBoxButtonWrapper = () => {
    return (
      <div className="button-wrapper">
        <div className="overlay-buttons-wrapper">
          <div className="overlay-buttons">
            {this.props.auth.user.coins >= (BOXCOST * 10) && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="10" onClick={() => this.buyBoxes(10)} />}
            {this.props.auth.user.coins >= (BOXCOST * 5) && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="5" onClick={() => this.buyBoxes(5)} />}
            {this.props.auth.user.coins >= (BOXCOST * 2) && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="1" onClick={() => this.buyBoxes(1)} />}
          </div>
          <input {...(this.state.busy || this.props.auth.user.coins < BOXCOST ? { disabled: true } : null)} type="button" value="Buy" onClick={this.props.auth.user.coins >= BOXCOST && this.props.auth.user.coins < (BOXCOST * 2) ? () => this.buyBoxes(1) : null} />
        </div>
        <div className="overlay-buttons-wrapper">
          <div className="overlay-buttons">
            {this.props.auth.user.boxes >= 10 && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="10" onClick={() => this.openBox(10)} />}
            {this.props.auth.user.boxes > 3 && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="5" onClick={() => this.openBox(5)} />}
            {this.props.auth.user.boxes > 1 && <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="1" onClick={() => this.openBox(1)} />}
          </div>
          <input {...(this.state.busy || this.props.auth.user.boxes < 1 ? { disabled: true } : null)} type="button" value="Open" onClick={this.props.auth.user.boxes === 1 ? () => this.openBox(1) : null} />
        </div>
      </div>
    )
  }

  avatarAlreadyUnlocked = (avatarId) => {
    const avatars = this.props.auth.user.unlockedAvatars.filter(avatarItem => avatarItem.id === avatarId)
    if (avatars.length === 0) {
      return false
    } else {
      return avatars.find(x => x.shiny === this.state.isShiny) !== undefined
    }
  }

  handleFuseAvatarClick = () => {
    if (this.state.fusing === null) {
      this.setState({
        fusing: [null, null]
      })
    } else if (this.state.fusing.length === 2) {
      this.fuseAvatar(this.state.avatarSelected)
    }
  }

  handleSellAvatarClick = () => {
    if (this.state.selling === null) {
      this.setState({
        selling: []
      })
    } else if (this.state.selling.length > 0) {
      this.sellAvatar(this.state.avatarSelected)
    }
  }

  getAvatarButtonWrapper = () => {
    const avatar = this.getSelectedAvatar()

    const unlockButton = { value: "Unlock" }
    if (this.state.busy || (avatar.quantity === 0 && !this.state.isShiny) || (avatar.shinyQuantity === 0 && this.state.isShiny)) {
      unlockButton.disabled = true
    }
    if (this.avatarAlreadyUnlocked(avatar.avatar.id)) {
      unlockButton.disabled = true
      unlockButton.value = "Unlocked"
    }

    return (
      <div className="button-wrapper">
        <input type="button" className={`unlock-btn${unlockButton.value === "Unlocked" ? ' unlocked' : ''}`} {...unlockButton} onClick={() => this.unlockAvatar(avatar.avatar.id)} />
        {(avatar.avatar.evolution) && <input {...((avatar.quantity + avatar.shinyQuantity) < 2 || this.state.busy ? { disabled: true } : null)} type="button" value="Fuse" onClick={() => this.handleFuseAvatarClick()} />}
        <input {...(this.state.busy ? { disabled: true } : null)} type="button" value="Sell" onClick={() => this.handleSellAvatarClick()} />
      </div>
    )
  }

  getLootPanel = () => {
    return (
      <div className="loot-wrapper">
        <div className="list-loots">
          {this.state.loots.avatars.sort((a, b) => a.avatar.index - b.avatar.index).map((avatar, index) => (
            <this.avatarItem
              key={index}
              avatar={avatar} />
          ))}
          {this.state.loots.coins !== 0 && (
            <div className='loot-money'>
              {this.state.loots.coins} COINS EARNED
            </div>
          )}
          {this.state.loots.unlockedAvatar !== null && (
            <div className={`unlocked-avatar${this.state.loots.unlockedAvatar.isLegendary ? ' legendary' : ''}`} style={{ backgroundImage: `url(${this.state.loots.unlockedAvatar.image}` }}>
              {this.state.loots.unlockedAvatar.shiny && <img className="star" src="/assets/images/star.png" alt="star_img" />}
            </div>
          )}
        </div>
        <div className="loot-button-wrapper">
          <input type="button" value="OK ty" onClick={this.handleCloseLoots} />
        </div>
      </div>
    )
  }

  handleCloseFuse = () => {
    this.setState({
      fusing: null
    })
  }

  handleCloseSell = () => {
    this.setState({
      selling: null
    })
  }

  handleClickAvatarFuseItem = (isShiny, index) => {
    const fusingUpdate = [...this.state.fusing]
    if (index !== undefined) {
      fusingUpdate[index] = null

      this.setState({
        fusing: fusingUpdate
      })
    } else {
      if (fusingUpdate[0] !== null && fusingUpdate[1] !== null) {
        return
      } else {
        if (fusingUpdate[0] !== null) {
          fusingUpdate[1] = isShiny
        } else {
          fusingUpdate[0] = isShiny
        }
        this.setState({
          fusing: fusingUpdate
        })
      }
    }
  }

  handleClickAvatarSellItem = (isShiny, index) => {
    const sellingUpdate = [...this.state.selling]
    if (index !== undefined) {
      sellingUpdate.splice(index, 1)
    } else {
      sellingUpdate.push(isShiny)
    }

    this.setState({
      selling: sellingUpdate
    })
  }

  fusingListIsFull = () => {
    return this.state.fusing[0] !== null && this.state.fusing[1] !== null
  }

  getFusePanel = () => {
    const avatar = this.getSelectedAvatar()

    const actualShinyQuantity = avatar.shinyQuantity - this.state.fusing.filter(x => x === true).length
    const actualQuantity = avatar.quantity - this.state.fusing.filter(x => x === false).length

    return (
      <div className="fuse-wrapper">
        <div className="avatar-fuse-list">
          {this.state.fusing.map((x, index) => (
            <this.avatarItem
              avatar={x !== null && { ...avatar, isShiny: x }}
              onClick={() => this.handleClickAvatarFuseItem(null, index)} />
          ))}
        </div>
        <div className="spacer"></div>
        <div className="avatar-template-list">
          <this.avatarItem
            avatar={avatar}
            counters={{ normal: actualQuantity }}
            classname={actualQuantity > 0 && !this.fusingListIsFull() ? '' : ' disabled'}
            onClick={() => actualQuantity > 0 && !this.fusingListIsFull() && this.handleClickAvatarFuseItem(false)} />
          <this.avatarItem
            avatar={{ ...avatar, isShiny: true }}
            counters={{ shiny: actualShinyQuantity }}
            classname={actualShinyQuantity && !this.fusingListIsFull() > 0 ? '' : ' disabled'}
            onClick={() => actualShinyQuantity > 0 && !this.fusingListIsFull() && this.handleClickAvatarFuseItem(true)} />
        </div>
        <div className="fuse-button-wrapper">
          <input type="button" value="Cancel" onClick={this.handleCloseFuse} />
          <input type="button" {...(!this.fusingListIsFull() || this.state.busy ? { disabled: true } : {})} value="Fuse" onClick={this.handleFuseAvatarClick} />
        </div>
      </div>
    )
  }

  getSellPanel = () => {
    const avatar = this.getSelectedAvatar()

    const actualShinyQuantity = avatar.shinyQuantity - this.state.selling.filter(x => x === true).length
    const actualQuantity = avatar.quantity - this.state.selling.filter(x => x === false).length
    const actualSellingCount = (this.state.selling && this.state.selling.length) || 0


    return (
      <div className="sell-wrapper">
        <div className="avatar-sell-list">
          {this.state.selling.map((x, index) => (
            <this.avatarItem
              key={index}
              avatar={{ ...avatar, isShiny: x }}
              onClick={() => this.handleClickAvatarSellItem(null, index)} />
          ))}
        </div>
        <div className="spacer"></div>
        <div className="money-wrapper">
          <img src="/assets/images/coin.png" alt="logo" />
          <div className="money-text">{actualSellingCount * avatar.cost}</div>
        </div>
        <div className="avatar-template-list">
          <this.avatarItem
            avatar={avatar}
            counters={{ normal: actualQuantity }}
            classname={actualQuantity > 0 && actualSellingCount < 18 ? '' : ' disabled'}
            onClick={() => actualQuantity > 0 && actualSellingCount < 18 && this.handleClickAvatarSellItem(false)} />
          <this.avatarItem
            avatar={{ ...avatar, isShiny: true }}
            counters={{ shiny: actualShinyQuantity }}
            classname={actualShinyQuantity > 0 && actualSellingCount < 18 ? '' : ' disabled'}
            onClick={() => actualShinyQuantity > 0 && actualSellingCount < 18 && this.handleClickAvatarSellItem(true)} />
        </div>
        <div className="sell-button-wrapper">
          <input type="button" value="Cancel" onClick={this.handleCloseSell} />
          <input type="button" {...(this.state.selling.length > 0 && !this.state.busy ? {} : { disabled: true })} value="Sell" onClick={this.handleSellAvatarClick} />
        </div>
      </div>
    )
  }

  getAvatarImage = (avatar, isShiny) => {
    const avatarModel = avatar ? avatar.avatar : null

    if (!avatarModel) {
      return null
    } else if (avatarModel && new RegExp(CONFIG.API_URL).test(avatarModel.image)) {
      return `url(${avatarModel.image})`
    } else {
      return `url(${CONFIG.API_URL}/assets/images/avatars/${isShiny ? avatarModel.shinyImage : avatarModel.image})`
    }
  }

  handleClickAvatarItem = (avatarId) => {
    this.setState({
      avatarSelected: avatarId === this.state.avatarSelected ? null : avatarId,
      boxSelected: false,
      isShiny: this.state.inventory.ownedAvatars.find(avatar => avatar.avatar.id === avatarId).quantity <= 0,
      fusing: null,
      selling: null,
      loots: {
        unlockedAvatar: null,
        avatars: [],
        coins: 0
      }
    })
  }

  getAvatarOptionWrapper = () => {
    const avatar = this.getSelectedAvatar()

    return (
      <div className="avatar-option-wrapper">
        <div className={`avatar-option-item${avatar.shinyQuantity > 0 ? ' active' : ''}`} style={{ backgroundImage: this.getAvatarImage(this.getSelectedAvatar(), true) }}>
          <img className="star" src="/assets/images/star.png" alt="star_img" />
          <input className={`select-shiny-button${this.state.isShiny ? ' selected' : ''}`} type="button" onClick={avatar.shinyQuantity > 0 ? () => this.toggleShiny() : null} />
        </div>
      </div>
    )
  }

  avatarItem = ({ avatar, classname, onClick, counters }) => {
    const avatarModel = avatar ? avatar.avatar : null

    let addClassName = ''
    if (avatarModel) {
      addClassName += avatarModel.isLegendary ? ' legendary' : ''
      if (!avatarModel.isLegendary) {
        switch (avatarModel.rank) {
          case 3:
            addClassName += ' epic'
            break
          case 2:
            addClassName += ' rare'
            break
          default:
            break
        }
      }
    }

    return (
      <div className={`avatar-item${addClassName}${classname ? ' ' + classname : ''}`} style={{ backgroundImage: this.getAvatarImage(avatar, avatar && avatar.isShiny) }} onClick={onClick}>
        <div className="avatar-count">
          {avatar && !counters && (
            <div className="count">
              <img src={avatar.isShiny ? '/assets/images/star.png' : '/assets/images/star-grey.png'} alt="star_img" />
            </div>
          )}
          {avatar && counters && counters.shiny !== undefined && (
            <div className="count">
              <div className="count-text">{counters.shiny}</div>
              <img src="/assets/images/star.png" alt="star_img" />
            </div>
          )}
          {avatar && counters && counters.normal !== undefined && (
            <div className="count">
              <div className="count-text">{counters.normal}</div>
              <img src="/assets/images/star-grey.png" alt="star_img" />
            </div>
          )}
        </div>
      </div>
    )
  }

  leftPanel = () => {
    return (
      <div className="left-panel">
        <div className="title">INVENTORY</div>
        <div className="subtitle">ITEMS</div>
        <div className="list-items">
          <div className={`box-wrapper${this.state.boxSelected ? ' selected' : ''}`} onClick={this.handleClickBoxWrapper}>
            <div className="box-count">{this.state.inventory ? this.props.auth.user.boxes : 0}</div>
          </div>
        </div>
        <div className="subtitle">AVATAR SHARDS</div>
        <div className="list-avatars">
          {this.state.inventory.ownedAvatars.sort((a, b) => a.avatar.index - b.avatar.index).map((avatar, index) => (
            <this.avatarItem
              key={index}
              classname={this.state.avatarSelected === avatar.avatar.id ? 'selected' : ''}
              avatar={avatar}
              counters={{ shiny: avatar.shinyQuantity ? avatar.shinyQuantity : undefined, normal: avatar.quantity ? avatar.quantity : undefined }}
              onClick={() => this.handleClickAvatarItem(avatar.avatar.id)} />
          ))}
        </div>
        <div className="button-wrapper">
          <input type="button" value="return" onClick={() => this.props.handleReturnClick()} />
          <div className="coin-wrapper"><div>{this.props.auth.user.coins}</div><img src="/assets/images/coin.png" alt="logo" /></div>
        </div>
      </div>
    )
  }

  rightPanel = () => {
    return (
      <div className="right-panel">
        <div className={`right-panel-background${(this.state.loots.avatars.length !== 0 || this.state.loots.coins !== 0) ? ' display-loots' : ''}${this.state.fusing !== null ? ' fusing' : ''}`} style={{ backgroundImage: this.getRightPanelBackground() }}>

        </div>
        <div className="selected-item-wrapper">
          {this.getRightPanelSelectedItemName() && (<div className="item-name">{this.getRightPanelSelectedItemName()}</div>)}
          {this.state.boxSelected && (
            <div className="money-wrapper">
              <div className="money-text">{BOXCOST}</div>
              <img src="/assets/images/coin.png" alt="logo" />
            </div>
          )}
          {this.state.avatarSelected && this.state.fusing === null && this.state.selling === null && this.getAvatarOptionWrapper()}
        </div>
        {(this.state.boxSelected && this.state.loots.avatars.length === 0 && this.state.fusing === null && this.state.selling === null) && this.getBoxButtonWrapper()}
        {this.state.avatarSelected && this.state.fusing === null && this.state.selling === null && this.getAvatarButtonWrapper()}
        {(this.state.loots.avatars.length !== 0 || this.state.loots.coins !== 0 || this.state.loots.unlockedAvatar !== null) && this.getLootPanel()}
        {this.state.fusing !== null && this.getFusePanel()}
        {this.state.selling !== null && this.getSellPanel()}
      </div>
    )
  }

  render() {
    return (
      <div className="inventory-panel-overlay">
        <div className="inventory-panel-wrapper">
          <this.leftPanel />
          <this.rightPanel />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryPanel)
import { lobbyConstants } from '../constants';

const initialState = { members: [], code: null, id: null };

export function lobby(state = initialState, action) {
  switch (action.type) {
    case lobbyConstants.LOBBY_CREATE:
      return {
        ...state,
        code: action.code
      };
    case lobbyConstants.LOBBY_ADD_MEMBER:
      return {
        ...state,
        members: [
          ...state.members,
          action.member
        ]
      };
    case lobbyConstants.LOBBY_REMOVE_MEMBER:
      return {
        ...state,
        members: [
          ...state.members.filter(member => member.id !== action.memberId),
        ]
      }
    case lobbyConstants.LOBBY_UPDATE_MEMBER:
      return {
        ...state,
        members: [
          action.member,
          ...state.members.filter(member => member.id !== action.memberId),
        ].sort((a, b) => a.isLobbyLeader ? -1 : (a.nickname < b.nickname ? -1 : 1))
      };
    default:
      return state
  }
}

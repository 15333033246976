import { authConstants } from "../constants"

export const login = token => ({
  type: authConstants.AUTH_LOGIN,
  token
})

export const updateUser = user => ({
  type: authConstants.AUTH_UPDATE_USER,
  user
})

export const logout = () => ({
  type: authConstants.AUTH_LOGOUT
})
import React, { Component } from 'react'
import HorizontalList from '../../presentationals/HorizontalList'
import { connect } from "react-redux"
import { updateCursor } from '../../actions/cursor'
import MySocket from '../../hoc/MySocket'

class GameList extends Component {
  componentDidMount() {
    this.initSocketEvents()
  }

  componentWillUnmount() {
    MySocket.removeOnMessageCallback('GameList')
  }

  joinRoom() {
    if (!this.props.lobby || !this.props.lobby.code) {
      return false
    }

    try {
      console.log('join room ' + this.props.lobby.code)
      this.props.socket.send(JSON.stringify({ title: 'join-room', data: { code: this.props.lobby.code } }))
    } catch (e) {
      console.log(e.message)
    }
  }

  lobbyHasLeader(lobby) {
    return lobby.members.find(member => member.isLobbyLeader) !== undefined
  }

  initSocketEvents = () => {
    MySocket.setOnMessageCallback('GameList', (message) => {
      switch (message.title) {
        case 'controller-action':
          this.onControllerAction(message.data)
          break
        default:
          break
      }
    })
  }

  onControllerAction = data => {
    if (!this.isValidCursor()) {
      return false
    }

    if (!data.member.isLobbyLeader) {
      return false
    }

    switch (data.action) {
      case "right":
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            index: this.props.cursor.element.index + 1 < this.props.games.length ? this.props.cursor.element.index + 1 : this.props.cursor.element.index
          }
        })
        break
      case "left":
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            index: this.props.cursor.element.index - 1 >= 0 ? this.props.cursor.element.index - 1 : this.props.cursor.element.index
          }
        })
        break
      case "ok":
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            ...this.props.cursor.element,
            selected: true
          }
        })
        break
      case "menu":
        this.startMiniGame()
        break
      default:
        break
    }
  }

  startMiniGame() {
    if (this.lobbyHasLeader(this.props.lobby)) {
      MySocket.socket.send(JSON.stringify({ title: 'lobby-start-minigame', data: { code: this.props.lobby.code } }))
      this.props.updateCursor({
        ...this.props.cursor,
        component: 'MiniGame'
      })
    }
  }

  isValidCursor() {
    return this.props.cursor && this.props.cursor.component === "GameList"
  }

  getCursorIndex() {
    return (this.isValidCursor() && this.props.cursor.element) ? this.props.cursor.element.index : -1
  }

  isActivated() {
    return (this.isValidCursor() && this.props.cursor.element) ? this.props.cursor.element.selected : false
  }

  gameToItem = (game, index) => ({
    title: game.title,
    subtitle: `${game.category} - ${game.playersMax > 1 ? `FROM ${game.playersMin} to ${game.playersMax} players` : 'SOLO'}`,
    background: game.picture,
    pin: game.status,
    link: this.props.lobby && this.props.lobby.members && this.props.lobby.members.length ? `/play/${game.title.replace(" ", "-").toLowerCase()}` : '/',
    selected: this.getCursorIndex() === index,
    isActivated: this.isActivated() && this.getCursorIndex() === index
  })

  render() {
    const { games = [] } = this.props

    return (
      <HorizontalList
        items={games.map((game, index) => this.gameToItem(game, index))}
      ></HorizontalList>
    )
  }
}

const mapStateToProps = ({ lobby, cursor }) => ({
  lobby,
  cursor
})

const mapDispatchToProps = dispatch => ({
  updateCursor: cursor => dispatch(updateCursor(cursor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameList)
import React, { Component } from 'react'
import GameListPanel from '../../containers/GameListPanel'
import LobbyPanel from '../../containers/LobbyPanel'
import { updateCursor } from '../../actions/cursor'
import { connect } from "react-redux"
import './style.scss'
import MiniGame from '../../containers/MiniGame'

class Home extends Component {

  componentDidMount() {
    if (this.lobbyHasLeader(this.props.lobby)) {
      this.props.updateCursor({
        page: 'home',
        component: 'GameList',
        element: {
          index: 0
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.lobbyHasLeader(prevProps.lobby) && this.lobbyHasLeader(this.props.lobby)) {
      this.props.updateCursor({
        page: 'home',
        component: 'GameList',
        element: {
          index: 0
        }
      })
    }
  }

  lobbyHasLeader(lobby) {
    return lobby.members.find(member => member.isLobbyLeader) !== undefined
  }

  render = () => (
    <div className="home page">
      <div className="left-panel">
        <GameListPanel></GameListPanel>
        {window.screen.availWidth >= 960 && (
          <div className="minigame-container">
            <MiniGame></MiniGame>
          </div>
        )}
      </div>
      {window.screen.availWidth >= 960 && (
        <div className="right-panel">
          <LobbyPanel></LobbyPanel>
        </div>
      )}
      {window.screen.availWidth < 960 && (
        <div className="download-panel">
          <div className="title">
            VGC is using Android apps for now (maybe iOS later), please download all apps!
            <br />
            What to do next? Open the VGC app on your phone and the website on your computer, link them, and play!
          </div>
          <div className="apk-list">
            <a download="vgc-controller.apk" href={"https://api.playdeviant.com/assets/apks/vgc-controller.apk?t=" + Date.now()} target="_blank" rel="noopener noreferrer">
              <div className="controller-apk">VGC Controller</div>
            </a>
            <a download="vgc-munchkin.apk" href={"https://api.playdeviant.com/assets/apks/vgc-munchkin.apk?t=" + Date.now()} target="_blank" rel="noopener noreferrer">
              <div className="munchkin-apk">VGC Munckin</div>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ lobby }) => ({
  lobby
})

const mapDispatchToProps = dispatch => ({
  updateCursor: cursor => dispatch(updateCursor(cursor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
import React, { Component } from 'react'
import LobbyPanel from '../LobbyPanel'
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { updateCursor } from '../../actions/cursor'
import MySocket from '../../hoc/MySocket'
import './style.scss'

class MenuPanel extends Component {
  state = {
    redirect: false
  }

  componentDidMount() {
    this.initSocketEvents()
  }

  componentWillUnmount() {
    MySocket.removeOnMessageCallback('MenuPanel')
  }

  lobbyHasLeader(lobby) {
    return lobby.members.find(member => member.isLobbyLeader) !== undefined
  }

  joinRoom() {
    if (!this.props.lobby || !this.props.lobby.code) {
      return false
    }

    try {
      console.log('join room ' + this.props.lobby.code)
      MySocket.socket.send(JSON.stringify({ title: 'join-room', data: { code: this.props.lobby.code } }))
    } catch (e) {
      console.log(e.message)
    }
  }

  initSocketEvents = () => {
    MySocket.setOnMessageCallback('MenuPanel', (message) => {
      switch (message.title) {
        case 'controller-action':
          this.onControllerAction(message.data)
          break
        default:
          break
      }
    })
  }

  onControllerAction = data => {
    console.log(data)
    if (!data.member.isLobbyLeader) {
      return false
    }

    if (data.action === "menu") {
      this.props.updateCursor({
        ...this.props.cursor,
        component: 'MenuPanel',
        element: {
          index: 0
        }
      })
    }

    if (this.props.cursor.component === "MenuPanel") {
      if (data.action === "up") {
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            index: this.props.cursor.element.index - 1 > 0 ? this.props.cursor.element.index - 1 : 0
          }
        })
      } else if (data.action === "down") {
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            index: this.props.cursor.element.index + 1 > 1 ? this.props.cursor.element.index + 1 : 1
          }
        })
      } else if (data.action === "nok") {
        this.props.updateCursor({
          ...this.props.cursor,
          element: {
            index: 1
          }
        })
      } else if (data.action === "ok") {
        if (this.isSelected(0)) {
          this.setState({
            redirect: '/home'
          })
        } else {
          this.props.onClickCloseMenuButton()
        }
      }
    }
  }

  isSelected(index) {
    return this.props.cursor.component === "MenuPanel" && this.props.cursor.element && this.props.cursor.element.index === index
  }

  render = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    if (this.props.cursor.component !== "MenuPanel") {
      return false
    } else {
      return (
        <div className="menu-panel">
          <div className="wrapper">
            <div className="left-wrapper">
              <div className="menu-panel-title">
                MENU
              </div>
              <div className="buttons-wrapper">
                <Link to="/home"><button className={this.isSelected(0) ? 'active' : ''}>GO TO HOME PAGE</button></Link>
                <button onClick={this.props.onClickCloseMenuButton} className={this.isSelected(1) ? 'active' : ''}>CLOSE MENU</button>
              </div>
            </div>
            <div className="right-wrapper">
              <LobbyPanel></LobbyPanel>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = ({ lobby, cursor }) => ({
  lobby,
  cursor
})

const mapDispatchToProps = dispatch => ({
  updateCursor: cursor => dispatch(updateCursor(cursor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuPanel)